<template>
  <div>
    <div v-if="documentExchange.id != null">
      <div style="display: flex;">
        <div style="width: 75%;">
          <p
            class="pre-text"
            style="text-align: left; color: #7D7D7D; display: inline-block; float: left;"
          >
            {{ documentExchange.name }}
          </p>
        </div>
        <div style="width: 25%;">
          <div style="display: flex; float: right; align-self: center;">
            <div style="cursor: pointer;" @click="onExpand()" class="mr-3">
              <div v-show="!isZoom">
                <i class="fas fa-expand expand"></i>
              </div>
              <div v-show="isZoom">
                <i class="fas fa-compress expand"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 mb-2 text-left p-0" style="margin-top: 0 !important;">
        <span>{{ $t("document.documentRecipient") }}:</span>
        <div
          style="display: block;"
          v-if="documentExchangeViewerList.length > 0"
        >
          <div
            v-for="(documentViewer, index) in documentExchangeViewerList"
            :key="documentViewer + index"
            style="display: inline-block;"
          >
            <div
              class="mr-1 mt-1"
              style="padding: 0px 4px 4px 4px; background-color: #41b883; color: #fff; border-radius: 5px;"
            >
              <span class="meeting-font-medium">{{
                documentViewer.full_name
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <iframe
            v-if="!validateStringIsNull(documentExchange.documentUrl)"
            :src="documentExchange.documentUrl + '#view=FitH'"
            style="width: 100%; height:auto; min-height: 640px; overflow-x: hidden;"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>

    <div v-else>
      {{ $t("scheduleMeeting.noDocument") }}
    </div>
  </div>
</template>
<script>
import functionUtils from "utils/functionUtils";
import { mapActions } from "vuex";
export default {
  props: {
    documentData: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data: () => ({
    documentExchange: {
      id: null,
      name: null,
      documentUrl: null,
    },
    isZoom: false,
    documentExchangeViewerList: []
  }),
  watch: {
    documentData: function() {
      if (Object.keys(this.documentData).length > 0) {
        this.documentExchange = {
          id: this.documentData.id,
          name: this.documentData.name,
          documentUrl: this.documentData.documentUrl,
        };
        this.getUserViewDocumentExchange()
      } else {
        this.documentExchange = {
          id: null,
          name: null,
          documentUrl: null,
        };
      }
    },
  },
  methods: {
    /**
     * On expand
     */
    onExpand: function() {
      this.isZoom = !this.isZoom;
      this.$emit('onZoomDocumentExchange', this.isZoom)
    },
    // Check null
    isNull: function(data) {
      return functionUtils.isNull(data);
    },
    isEmptyString: function(data) {
      return functionUtils.isEmptyString(data);
    },
    validateStringIsNull: function(data) {
      return functionUtils.validateStringIsNull(data);
    },
    getUserViewDocumentExchange: function() {
      let filter = {
        params: {
          documentId: this.documentData.id,
        },
      };
      this.GET_USER_VIEW_DOCUMENT_EXCHANGE(filter).then(
        function(res) {
          let data = res.data;
          this.documentExchangeViewerList = [];
          data.forEach((userValue) => {
            let full_name = userValue.last_name + " " + userValue.first_name;
            let item = {
              id: userValue.id,
              full_name: full_name,
              url_avatar:
                userValue.url_avatar != null || userValue.url_avatar != ""
                  ? userValue.url_avatar
                  : null,
              is_group: userValue.is_group,
              email: userValue.email,
            };
            this.documentExchangeViewerList.push(item);
          });
        }.bind(this)
      );
    },
    ...mapActions(["GET_USER_VIEW_DOCUMENT_EXCHANGE"]),
  },
  mounted() {},
};
</script>
<style scoped>
.expand {
  color: #f2404d;
}
</style>
