<template>
  <div>
    <!-- Choose content -->
    <div v-if="addDocumentType != AddDocumentTypePersonal">
      <div v-if="!isUpdateDocumentForEventSession">
        <span style="font-size: small;">{{ $t("document.chooseContent") }}</span>
        <span style="font-size: small; font-weight: bold;" v-if="agendaSelected.name">{{ ': ' + agendaSelected.name }}</span>
      </div>
      <div v-else-if="isUpdateDocumentForEventSession">
        <span style="font-size: small; font-weight: bold;">{{ documentTitle }}</span>
      </div>
      <!-- Search for contents -->
      <div v-if="!isAddDocumentForEventSessionAssign" style="position: relative;">
        <i
          class="fas fa-search"
          style="position: absolute; left: 10px; top: 30%; color: #cccccc; font-size: small;"
        ></i>
        <avatar-select-input
          :headers="agendaHeaders"
          :items="agendaList"
          :placeholder="$t('document.searchForContent')"
          @select="agendaSelect"
        ></avatar-select-input>
      </div>
    </div>
    <!-- Upload new document file -->
    <div class="mt-3" style="position: relative;">
      <label for="file-upload" class="custom-file-upload">
        <i
          class="fas fa-file-upload"
          style="position: absolute; left: 10px; top: 30%; color: #cccccc; font-size: small;"
        ></i>
        <span style="font-size: small; word-wrap: break-word;">{{
          documentFileName ? documentFileName : $t("document.uploadNewDocument")
        }}</span>
      </label>
      <input v-on:change="onFileChange" id="file-upload" type="file" accept="application/pdf"/>
    </div>
    <!-- Enter title document -->
    <div class="mt-3">
      <span style="font-size: small;">{{ $t("document.documentTitle") }}</span>
      <textarea
        v-model="documentTitle"
        class="form-control mt-2"
        style="font-size: small;"
        :placeholder="$t('document.enterTitle')"
        rows="2"
        v-bind:class="{
          'form-control': true,
          'is-invalid': !validRequire(documentTitle) && fieldBlured,
        }"
        v-on:blur="fieldBlured = true"
      ></textarea>
      <div class="invalid-feedback" style="text-align: left;">
        {{ $t("document.fieldRequire.documentTitle") }}
      </div>
    </div>
    <!-- Security and need approval -->
    <div v-if="addDocumentType != AddDocumentTypePersonal">
      <div class="row mt-3">
        <div class="col-3">
          <span style="font-size: small;">{{ $t("document.security") }}</span>
        </div>
        <div class="col-6">
          <div
            v-show="security"
            style="display: inline-block; cursor: pointer;"
            @click="setSecurity()"
          >
            <i class="far fa-check-square mr-2" style="color: green;"></i>
          </div>
          <div
            v-show="!security"
            style="display: inline-block; cursor: pointer;"
            @click="setSecurity()"
          >
            <i class="far fa-square mr-2" style="color: green;"></i>
          </div>
          <span v-if="security" style="font-size: small;">
            {{ $t("document.yes") }}
          </span>
          <span v-else style="font-size: small;">
            {{ $t("document.no") }}
          </span>
        </div>
      </div>
      <!--  -->
      <div v-if="needApproval" class="mt-3" style="position: relative;">
        <span style="font-size: small;">{{ $t("document.assignApprover") }}</span>
        <VueMutiselect
          ref="approverMutiSelect"
          class="mt-3"
          :optionData="approverList"
          :customLabel="customLabel"
          :labelText="labelTextValue"
          :trackByText="trackByTextValue"
          :placeHolder="$t('mission.selectPerson')"
          @select="getAssigner"
        ></VueMutiselect>
      </div>
      <!-- Message for approver -->
      <div v-if="needApproval" class="mt-3">
        <span style="font-size: small;">{{
          $t("document.messageForApprover")
        }}</span>
        <textarea
          v-model="message"
          class="form-control mt-2"
          style="font-size: small;"
          :placeholder="$t('document.enterMessage')"
          rows="2"
        ></textarea>
      </div>
    </div>
    <!-- Approval -->
    <!-- <div class="row mt-3">
      <div class="col-3">
        <span style="font-size: small;">{{ $t("document.needApproval") }}</span>
      </div>
      <div class="col-6">
        <div
          v-show="needApproval"
          style="display: inline-block; cursor: pointer;"
          @click="setApproval()"
        >
          <i class="far fa-check-square mr-2" style="color: green;"></i>
        </div>
        <div
          v-show="!needApproval"
          style="display: inline-block; cursor: pointer;"
          @click="setApproval()"
        >
          <i class="far fa-square mr-2" style="color: green;"></i>
        </div>
        <span v-if="needApproval" style="font-size: small;">
          {{ $t("document.yes") }}
        </span>
        <span v-else style="font-size: small;">
          {{ $t("document.no") }}
        </span>
      </div>
    </div> -->
    <!-- Search for approvers -->
    <!-- <div v-if="needApproval" class="mt-3" style="position: relative;">
      <span style="font-size: small;">{{ $t("document.assignDocumentViewer") }}</span>
      <VueMutiselect
        ref="approverMutiSelect"
        class="mt-3"
        :optionData="approverList"
        :customLabel="customLabel"
        :labelText="labelTextValue"
        :trackByText="trackByTextValue"
        :placeHolder="$t('mission.selectPerson')"
        @select="getAssigner"
      ></VueMutiselect>
    </div> -->
    <div class="mt-3 text-left">
      <button
        @click="checkUploadFileForm()"
        type="submit"
        class="btn submit-button"
        style="font-size: small;"
      >
        {{ !isUpdateDocumentForEventSession ? $t("document.addDocument") : $t('userProfile.update')}}
      </button>
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import AvatarSelectInput from "Components/AvatarSelectInput";
import functionUtils from 'utils/functionUtils'
import DocumentStatus from 'enum/documentStatus'
import VueMutiselect from "Components/VueMutiselect";
import AddDocumentType from 'enum/addDocumentType'
import Enum from 'enum/enum';
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
export default {
  components: {
    VueMutiselect,
    avatarSelectInput: AvatarSelectInput
  },
  data: () => ({
    documentFileName: null,
    mediaServerToken: null,
    mediaServerDomain: null,
    documentFile: null,
    eventId: null,
    security: true,
    needApproval: true,
    agendaList: [],
    approverList: [],
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    approverHeaders: [
      {
        key: 'check',
        name: ''
      },
      {
        key: 'fullname',
        name: 'userProfile.fullName'
      },
      {
        key: 'position',
        name: 'userProfile.companyPosition'
      },
      {
        key: 'role',
        name: 'document.role.title'
      }
    ],
    agendaHeaders: [
      {
        key: 'fullname',
        name: 'document.content'
      }
    ],
    agendaSelected: {
      id: null,
      name: ''
    },
    documentTitle: '',
    approverSelected: [],
    message: '',
    isAddDocumentForEventSessionAssign: false,
    isUpdateDocumentForEventSession: false,
    documentId: null,
    fieldBlured: false,
    event:{
      name: ''
    },
    addDocumentType: null,
    AddDocumentTypePersonal: AddDocumentType.PERSONAL,
    AddDocumentTypeNormal: AddDocumentType.NORMAL,
    userList: [],
    groupList: []
  }),
  computed: {
    ...mapGetters([
      "GET_TOKEN_MEDIA_SERVER_DATA",
      "GET_SNACK_BAR",
      "GET_EVENT_SESSIONS_DATA",
      "GET_USER_IN_EVENT_LIST_DATA",
      "EVENT_DETAIL_DATA",
      "GET_CURRENT_ACCOUNT"
    ])
  },
  watch: {
    GET_USER_IN_EVENT_LIST_DATA: function() {
      this.approverList = [];
      if (!functionUtils.isNull(this.GET_USER_IN_EVENT_LIST_DATA)) {
        this.GET_USER_IN_EVENT_LIST_DATA.forEach((data) => {
          let full_name = data.lastname + " " + data.firstname;
          let item = {
            id: data.id,
            full_name: full_name,
            url_avatar:
              data.url_avatar != null || data.url_avatar != ""
                ? data.url_avatar
                : null,
            is_group: data.is_group
          };
          this.approverList.push(item);
        });
      }
    },
    GET_EVENT_SESSIONS_DATA: function() {
      this.agendaList = [];
      let eventSessionsData = this.GET_EVENT_SESSIONS_DATA;
      for (let i = 0, size = eventSessionsData.length; i < size; i++) {
        let eventSessionObj = {
          id: eventSessionsData[i].id,
          fullname: eventSessionsData[i].title,
          position: ""
        };
        this.agendaList.push(eventSessionObj);
      }
    },
    GET_TOKEN_MEDIA_SERVER_DATA: function() {
      this.mediaServerToken = this.GET_TOKEN_MEDIA_SERVER_DATA.data.token;
      this.mediaServerDomain = this.GET_TOKEN_MEDIA_SERVER_DATA.data.domain;
    },
    EVENT_DETAIL_DATA: function(){
      this.event.name = this.EVENT_DETAIL_DATA.name;
    }
  },
  methods: {
    /**
     * Check upload file form
     */
    checkUploadFileForm: function () {
      this.fieldBlured = true;
      if (!this.needApproval) {
        if (this.validRequire(this.documentTitle)) {
          if (functionUtils.isNull(this.documentFileName)) {
            this.ON_SHOW_TOAST({
              'message': this.$t('document.fieldRequire.chooseDocument'),
              'styleType': 'danger'
            })
          } else {
            this.uploadFileToServer();
          }
        }
      } else {
        if (this.validRequire(this.documentTitle)) {
          if (functionUtils.isNull(this.documentFileName)) {
            this.ON_SHOW_TOAST({
              'message': this.$t('document.fieldRequire.chooseDocument'),
              'styleType': 'danger'
            })
          } else {
            this.uploadFileToServer();
          }
        }
      }
    },
    /**
     * Valid require
     */
    validRequire: function(fieldRequire) {
      if (fieldRequire != "") {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Add document for personal
     */
    addDocumentForPersonal: function () {
      this.addDocumentType = AddDocumentType.PERSONAL
    },
    /**
     * Add document for event session assign
     */
    addDocumentForEventSessionAssign: function (meetingResource) {
      this.isAddDocumentForEventSessionAssign = true
      this.isUpdateDocumentForEventSession = false
      this.addDocumentType = AddDocumentType.NORMAL
      this.agendaSelected.id = meetingResource && meetingResource.id
      this.agendaSelected.name = meetingResource && meetingResource.description
      this.getAttendeeSpeakerList()
    },
    /**
     * Add document for event session custom
     */
    addDocumentForEventSessionCustom: function () {
      this.isAddDocumentForEventSessionAssign = false
      this.isUpdateDocumentForEventSession = false
      this.agendaSelected.id = null
      this.agendaSelected.name = null
      this.addDocumentType = AddDocumentType.NORMAL
      this.getAttendeeSpeakerList()
    },
    /**
     * Update document for event session
     */
    updateDocumentForEventSession: function (documentId, documentName) {
      this.documentId = documentId
      this.documentTitle = documentName
      this.isAddDocumentForEventSessionAssign = true
      this.isUpdateDocumentForEventSession = true
      this.getAttendeeSpeakerList()
    },
    /**
     * Agenda select
     */
    agendaSelect: function(item) {
      this.agendaSelected.id = item.id
      this.agendaSelected.name = item.fullname
    },
    /**
     * Approver selected
     */
    approverSelect: function(item) {
      if (!item.selected) {
        this.approverSelected.push(item)
      } else if (item.selected) {
        for (let i = 0, size = this.approverSelected.length; i < size; i++) {
          if (this.approverSelected[i].id == item.id) {
            this.approverSelected.splice(i, 1)
            break;
          }
        }
      }
    },
    /**
     * Set need approval
     */
    setApproval: function() {
      this.needApproval = !this.needApproval;
    },
    /**
     * Set security
     */
    setSecurity: function() {
      this.security = !this.security;
    },
    /**
     * On image change
     */
    onFileChange: function(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.documentFileName = files[0].name;
      this.documentFileSize = files[0].size;
      this.getResultFile(files[0]);
    },
    /**
     * Get result file
     */
    getResultFile: function(file) {
      let vm = this;
      vm.documentFile = file;
    },
    /**
     * Upload file to server
     */
    uploadFileToServer: function() {
      let extensionsFile = functionUtils.getFileExtensions(this.documentFileName)
      // Invalid if file bigger 10MB
      if (this.documentFileSize > 10000000) {
        this.ON_SHOW_TOAST({
          message: this.$t("document.cannotExceed10MB"),
          styleType: "danger"
        });
      } else if (extensionsFile.toLowerCase().trim() != 'pdf') {
        this.ON_SHOW_TOAST({
          message: this.$t('document.plsUploadPdfFile'),
          styleType: "danger"
        });
      } else {
        let formData = new FormData();
        if (this.addDocumentType == this.AddDocumentTypePersonal) {
          // Change aias and replace space to '_' character
          let documentNameChangeAlias = functionUtils.changeAlias(this.documentFileName)
          documentNameChangeAlias = documentNameChangeAlias.split(' ').join('_');
          formData.append("file", this.documentFile, documentNameChangeAlias);
          formData.append("directory", this.GET_CURRENT_ACCOUNT.id + "/" + "personal/documents");
        } else {
          formData.append("file", this.documentFile);
          formData.append("directory", "documents/" + this.eventId);
        }
        axios({
          method: "POST",
          timeout: 6000,
          url: this.mediaServerDomain + (this.addDocumentType == this.AddDocumentTypePersonal ? "/api/v1/upload/personal/file/" : "/api/v1/upload/"),
          data: formData,
          headers: { Authorization: `JWT ${this.mediaServerToken}` }
        })
          .then(
            function(res) {
              let url = res.data.domain + res.data.url
              let filter = {}
              if (this.addDocumentType == this.AddDocumentTypePersonal) {
                filter = {
                  name: this.documentTitle,
                  url: url,
                  event: this.eventId,
                  event_session: null,
                  document_type: null,
                  status: DocumentStatus.PERSONAL_UPLOAD,
                  document_poster: null,
                  speaker: null,
                  message: null,
                  save_document_type: 0,
                  security: true
                }
              } else {
                filter = {
                  name: this.documentTitle,
                  url: url,
                  event: this.eventId,
                  event_session: this.agendaSelected.id,
                  document_type: null,
                  status: DocumentStatus.AWAITING_APPROVAL,
                  document_poster: null,
                  need_approval: this.needApproval,
                  speaker: null,
                  message: this.message,
                  save_document_type: 0,
                  approvers: this.approverSelected,
                  security: this.security
                }
              }
              if (this.isUpdateDocumentForEventSession) {
                this.UPDATE_DOCUMENT({ id: this.documentId, ...filter }).then(
                  function () {
                    this.sendEmail()
                    if (this.addDocumentType !== AddDocumentType.PERSONAL) {
                      this.sendNotification();
                    }
                    this.setDefault()
                    this.ON_SHOW_TOAST({
                      message: this.$t("common.success"),
                      styleType: "success"
                    });
                    this.$emit('addDocumentSuccess', this.addDocumentType)
                  }.bind(this)
                ).catch(
                  function () {
                    this.ON_SHOW_TOAST({
                      message: this.$t("common.somethingWentWrong"),
                      styleType: "danger"
                    });
                  }.bind(this)
                )
              } else {
                this.CREATE_DOCUMENT(filter).then(
                  function () {
                    this.sendEmail()
                    if (this.addDocumentType !== AddDocumentType.PERSONAL) {
                      this.sendNotification();
                    }
                    this.setDefault()
                    this.ON_SHOW_TOAST({
                      message: this.$t("common.success"),
                      styleType: "success"
                    });
                    this.$emit('addDocumentSuccess', this.addDocumentType)
                  }.bind(this)
                ).catch(
                  function () {
                    this.ON_SHOW_TOAST({
                      message: this.$t("common.somethingWentWrong"),
                      styleType: "danger"
                    });
                  }.bind(this)
                )
              }
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t("common.somethingWentWrong"),
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    /**
     * Get attendees speakers
     */
    getAttendeeSpeakerList: function () {
      let attendesSpeakersFilter = {
        params: {
          event: this.eventId,
          bandGroup: true
        }
      };
      this.GET_USER_IN_EVENT_LIST(attendesSpeakersFilter)
        .then(
          function() {
            // Todo
          }.bind(this)
        )
        .catch(
          function() {
          }.bind(this)
        );
    },
    /**
     * Get uploader
     */
    getAssigner(data) {
      this.approverSelected = [];
      this.groupList = []
      this.userList = []
      data.forEach((item) => {
        this.approverSelected.push({id: item.id, is_group: item.is_group});
        if (item.is_group) {
          this.groupList.push(item.id)
        } else {
          this.userList.push(item.id)
        }
      });
    },
    /**
     * Customize label
     */
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    // Set default data
    setDefault: function () {
      this.documentFileName = null
      this.approverSelected = []
      // if (this.needApproval) {
      //   this.$refs.approverMutiSelect.clearAll()
      // }
      this.message = ""
      this.documentTitle = ""
    },
    // 
    sendNotification(){
      let data = {
        event_id: this.eventId,
        // message_title: this.event.name,
        // message_body: this.$t('notification.document.needApproval', {'0': this.documentFileName, '1': this.event.name}),
        send_chairmain_and_secretary: true,
        user_list: this.userList,
        group_list: this.groupList,
        action_code: TemplateCategoriesEnum.DOCUMENT_WAITING_APPROVAL,
        template_type: Enum.TemplateType.Notification,
        document_name: this.documentFileName,
        notification_type_code: Enum.NotificationType.DocumentNeedApproval
      }
      this.SEND_NOTIFICATION_IN_MEETING(data)

      // let smsContent = this.$t('notification.document.needApproval', {'0': this.documentFileName, '1': this.event.name})
      let smsData = {
        event_id: this.eventId,
        // content: functionUtils.uniDecodeString(smsContent),
        send_chairmain_and_secretary: true,
        action_code: TemplateCategoriesEnum.DOCUMENT_WAITING_APPROVAL,
        template_type: Enum.TemplateType.SMS,
        document_name: this.documentFileName
      }
      this.SEND_SMS_IN_MEETING(smsData)
    },
    sendEmail: function(){
      let userList = []
      let groupList = []
      this.approverSelected.forEach(data=>{
        if (data.is_group==true){
          groupList.push(data.id)
        }else{
          userList.push(data.id)
        }
      })
      let data = {
        action_code: TemplateCategoriesEnum.DOCUMENT_WAITING_APPROVAL,
        template_type: Enum.TemplateType.Email,
        event_id: this.eventId,
        send_chairmain_and_secretary: true,
        document_name: this.documentFileName,
        user_list: userList,
        group_list: groupList
      }
      this.SEND_EMAIL_WITH_TEMPLATE_CONFIG(data)
    },
    ...mapActions([
      "GET_TOKEN_MEDIA_SERVER",
      "ON_SHOW_TOAST",
      "GET_EVENT_SESSION",
      "GET_USER_IN_EVENT_LIST",
      "CREATE_DOCUMENT",
      "UPDATE_DOCUMENT",
      "SEND_NOTIFICATION_IN_MEETING",
      "GET_EVENT_DETAIL",
      "SEND_SMS_IN_MEETING",
      "SEND_EMAIL_WITH_TEMPLATE_CONFIG"
    ])
  },
  created() {
    this.eventId = sessionStorage.getItem("event_id");
    // Get event session (agenda)
    this.agendaList.push({
      fullname: this.$t('document.loading')
    });
    let filter = {
      params: {
        eventId: this.eventId
      }
    };
    this.GET_EVENT_SESSION(filter)
      .then(
        function() {
          // Todo
        }.bind(this)
      )
      .catch(
        function() {
        }.bind(this)
      );
    let event = {
      params:{
        is_meeting: true
      },
      id: this.eventId
    }
    this.GET_EVENT_DETAIL(event)
  },
  mounted() {
    this.GET_TOKEN_MEDIA_SERVER();
  }
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.submit-button {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.3, $light-primary),
    color-stop(0.7, $primary)
  );
  color: #ffffff;
}
input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 25px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
}
</style>
