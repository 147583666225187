<template>
  <div class="text-left">
    <!-- Document note -->
    <div>
      <div class="general-calendar-title position-relative">
        <div class="row">
          <div class="col-12">
            <span class="pre-text">{{ $t('document.documentNote') }}</span>
          </div>
        </div>
      </div>
      <!-- Document file -->
      <div v-if="documentsPersonalNote.length == 0" style="text-align: center;">
        <span>{{ $t("scheduleMeeting.noDocument") }}</span>
      </div>
      <div v-else>
        <div
          v-for="(documentNote, index) in documentsPersonalNote"
          :key="documentNote + index"
        >
          <div :style="'cursor: pointer !important; margin-left: 15px;' + (index == 0 ? 'margin-top: 5px;' : (index == documentsPersonalNote.length - 1 ? 'margin-bottom: 5px' : ''))">
            <div
              :class="index != 0 ? 'mt-2' : ''"
              @click="onSelectDocumentPersonal(documentNote)"
            >
              <img
                class="mr-2"
                src="../../assets/icons/file-pdf.svg"
                style="width: 16px; height: 35px;"
              />
              <span class="pre-text" :style="documentId == documentNote.id ? 'font-weight: bold' : ''">{{
                documentNote.name
              }}</span>
              <div style="display: inline-block;">
                <div style="display: inline-block;">
                  <i class="fas fa-chevron-right ml-3"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Document upload -->
    <div>
      <div class="general-calendar-title position-relative">
        <div class="row">
          <div class="col-12">
            <span class="pre-text">{{ $t('document.documentUpload') }}</span>
          </div>
        </div>
      </div>
      <!-- Document file -->
      <div v-if="documentsPersonalUpload.length == 0" style="text-align: center;">
        <span>{{ $t("scheduleMeeting.noDocument") }}</span>
      </div>
      <div v-else>
        <div
          v-for="(documentUpload, index) in documentsPersonalUpload"
          :key="documentUpload + index"
          class="document-personal-upload"
        >
          <!-- <div :style="'cursor: pointer !important; margin-left: 15px;' + (index == 0 ? 'margin-top: 5px;' : (index == documentsPersonalNote.length - 1 ? 'margin-bottom: 5px' : ''))">
            <div
              :class="index != 0 ? 'mt-2' : ''"
              @click="onSelectDocumentPersonal(documentUpload)"
            >
              <img
                class="mr-2"
                src="../../assets/icons/file-pdf.svg"
                style="width: 16px; height: 35px;"
              />
              <span class="pre-text" :style="documentId == documentUpload.id ? 'font-weight: bold' : ''">{{
                documentUpload.name
              }}</span>
              <div style="display: inline-block;">
                <div style="display: inline-block;">
                  <i class="fas fa-chevron-right ml-3"></i>
                </div>
              </div>
            </div>
          </div> -->
          <div 
            :style="'cursor: pointer !important; margin-left: 15px;' + (index == 0 ? 'margin-top: 5px;' : (index == documentsPersonalNote.length - 1 ? 'margin-bottom: 5px' : ''))"
            :class="`justify-content-between d-flex ${index != 0 ? 'mt-2' : ''}`"
          >
            <div 
              class="w-100" 
              @click="onSelectDocumentPersonal(documentUpload)"
            >
              <img
                class="mr-2"
                src="../../assets/icons/file-pdf.svg"
                style="width: 16px; height: 35px;"
              />
              <span class="pre-text" :style="documentId == documentUpload.id ? 'font-weight: bold' : ''">{{
                documentUpload.name
              }}</span>
              <i class="fas fa-chevron-right ml-3"></i>
            </div>
            <div class="align-self-center mr-2">
              <img src="../../assets/sidebar_icon/bar.svg" alt="" data-toggle="dropdown">
              <ul
                class="dropdown-menu float-right dropdown-menu-right"
                role="menu"
                style="max-height: 300px; overflow-y: auto;"
                aria-labelledby="dropdownMenuButton"
              >
                <li class="mb-2 document-personal-upload-dropdown" @click="onShowModal(documentUpload)">
                  <img src="../../assets/icons/share-alt-solid.svg" class="pl-2 pr-2" alt="">
                  <span >{{$t('document.shareDocument')}}</span>
                </li>
                <li class="document-personal-upload-dropdown" @click="onDeleteDocumentPersonal(documentUpload)">
                  <img src="../../assets/icons/trash-alt-solid.svg" class="pl-2 pr-2" alt="">
                  <span >{{$t('common.delete')}}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div @click="onAddDocumentPersonal()" class="add-resource" style="">
      <div style="text-align: center;">
        <i class="fas fa-plus icon-plus-style" style="color: #26BA50 !important;"></i>
        <span style="color: #26BA50;">{{ $t('document.addDocument') }}</span>
      </div>
    </div>
    <!-- document exchange -->
    <div class="mt-3">
      <div class="general-calendar-title position-relative">
        <div class="row">
          <div class="col-12">
            <span class="pre-text">{{ $t('document.exchangedDocument') }}</span>
          </div>
        </div>
      </div>
      <!-- Document file -->
      <div v-if="documentExchangeList.length == 0" style="text-align: center;">
        <span>{{ $t("scheduleMeeting.noDocument") }}</span>
      </div>
      <div v-else>
        <div
          v-for="(documentUpload, index) in documentExchangeList"
          :key="documentUpload + index"
          class="document-personal-upload"
        >
          <div :style="'cursor: pointer !important; margin-left: 15px;' + (index == 0 ? 'margin-top: 5px;' : (index == documentsPersonalNote.length - 1 ? 'margin-bottom: 5px' : ''))">
            <div
              :class="index != 0 ? 'mt-2' : ''"
              @click="onSelectDocumentPersonal(documentUpload)"
            >
              <img
                class="mr-2"
                src="../../assets/icons/file-pdf.svg"
                style="width: 16px; height: 35px;"
              />
              <span class="pre-text" :style="documentId == documentUpload.id ? 'font-weight: bold' : ''">{{
                documentUpload.name
              }}</span>
              <div style="display: inline-block;">
                <div style="display: inline-block;">
                  <i class="fas fa-chevron-right ml-3"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
    <confirm-modal ref="deleteDocumentPersonalModal" :content="documentPersonalModalContent" @onConfirm="confirmDeleteDocumentPersonal()"></confirm-modal>
    <!--  -->
    <!--  -->
    <b-modal
      v-model="isShow"
      :title="$t('common.confirm')"
      centered
      id="modal-center"
    >
      <div>
        <div class="row">
          <div class="col-md-12 mb-2" style="margin-top: 0 !important;">
            <span>{{ $t("document.documentRecipient") }}:</span>
            <div style="display: block;" v-if="documentExchangeViewerList.length > 0">
              <div
                v-for="(documentViewer, index) in documentExchangeViewerList"
                :key="documentViewer + index"
                style="display: inline-block;"
              >
                <div
                  class="mr-1 mt-1"
                  style="padding: 0px 4px 4px 4px; background-color: #41b883; color: #fff; border-radius: 5px;"
                >
                  <span class="meeting-font-medium">{{ documentViewer.full_name }}</span>
                  <span
                    @click="removeCurrentDocumentViewer(documentViewer)"
                    class="meeting-font-small"
                    style="background-color: #369a6e; padding: 2px 5px 2px 5px; cursor: pointer; border-radius: 5px;"
                  >X</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="form-group">
              <span style="font-size: 16px;">{{
                $t("document.selectDocumentRecipient")
              }}</span>
              <vueMutiselect
              :optionData="optionData"
              :customLabel="customLabel"
              :labelText="labelTextValue"
              :trackByText="trackByTextValue"
              :closeOnSelect="false"
              :placeHolder="$t('mission.selectPerson')"
              @select="getAssignerSelect"
            ></vueMutiselect>
            </div>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right btn-submit ml-3"
            style="border: none;"
            @click="onExchangeDocument()"
          >
            {{ $t('common.accept') }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="background-color: #fff; color: #000; border: none;"
            @click="isShow = false"
          >
            {{ $t('common.close') }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AddDocumentType from 'enum/addDocumentType'
import DocumentStatus from 'enum/documentStatus'
import ConfirmModal from 'Components/ConfirmModal'
import VueMutiselect from "Components/VueMutiselect";
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
import Enum from 'enum/enum';
export default {
  components: {
    ConfirmModal,
    VueMutiselect
  },
  data: () => ({
    documentsPersonalNote: [],
    documentsPersonalUpload: [],
    documentId: null,
    documentSelect: {
      name: '',
      file: ''
    },
    documentsPersonalUploadSelect: null,
    documentPersonalModalContent: '',
    isShow: false,
    optionData: [],
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    userExchangeDocumentSelectList: [],
    documentExchangeViewerList: [],
    documentExchangeList: []
  }),
  computed: {
    ...mapGetters(["DOCUMENT_PERSONAL_DATA", "GET_SNACK_BAR", "GET_USER_IN_EVENT_LIST_DATA", "GET_CURRENT_ACCOUNT"]),
  },
  watch: {
    DOCUMENT_PERSONAL_DATA: function() {
      let data = this.DOCUMENT_PERSONAL_DATA
      this.documentsPersonalNote = []
      this.documentsPersonalUpload = []
      this.documentExchangeList= []
      for (let i = 0, size = data.length; i < size; i++) {
        if (data[i].is_document_exchange) {
          this.documentExchangeList.push(data[i])
        }
        else if (data[i].status == DocumentStatus.PERSONAL) {
          this.documentsPersonalNote.push(data[i])
        } else if (data[i].status == DocumentStatus.PERSONAL_UPLOAD) {
          this.documentsPersonalUpload.push(data[i])
        }
      }
    },
    GET_USER_IN_EVENT_LIST_DATA() {
      this.optionData = [];
      this.GET_USER_IN_EVENT_LIST_DATA.forEach(data => {
        let full_name = data.lastname + " " + data.firstname;
        let item = {
          id: data.id,
          full_name: full_name,
          url_avatar:
            data.url_avatar != null || data.url_avatar != ""
              ? data.url_avatar
              : null,
          is_group: data.is_group,
          email: data.email
        };
        this.optionData.push(item);
      });
    }
  },
  created() {
    let eventId = sessionStorage.getItem('event_id')
    let filter = {
      params: {
        eventId: eventId
      }
    }
    this.GET_DOCUMENT_PERSONAL(filter);
    let user_filter = {
      params: {
        event: eventId,
        bandGroup: true,
        excludeCurrentUserLogin: true
      }
    };
    this.GET_USER_IN_EVENT_LIST(user_filter);
  },
  methods: {
    /** 
      * Add document personal
      */
    onAddDocumentPersonal: function () {
      this.$emit('addDocumentPersonal', AddDocumentType.PERSONAL)
    },
    /**
     * Select document
     */
    onSelectDocumentPersonal: function(document) {
      this.$emit("selectDocumentPersonal", document);
      this.documentId = document.id
    },
    onDeleteDocumentPersonal(documentUpload) {
      this.documentsPersonalUploadSelect = documentUpload
      this.documentPersonalModalContent = this.$t('document.deleteDocumentPersonalTitle')
      this.$refs.deleteDocumentPersonalModal.onShowModalConfirm()
    },
    confirmDeleteDocumentPersonal: function () {
      this.DELETE_DOCUMENT_PERSONAL(this.documentsPersonalUploadSelect)
        .then(
          function() {
            this.getDocumentPersonal()
          }.bind(this)
        )
        .catch(
          function() {
            this.isLoading = false;
          }.bind(this)
        );
    },
    getDocumentPersonal: function () {
      let eventId = sessionStorage.getItem('event_id')
      let filter = {
        params: {
          eventId: eventId
        }
      }
      this.GET_DOCUMENT_PERSONAL(filter);
    },
    onShowModal: async function (document) {
      this.documentsPersonalUploadSelect = document
      await this.getAllUserInEvent()
      await this.getUserViewDocumentExchange()
      this.isShow = true
    },
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    getAssignerSelect: function (data) {
      if (data.length > 0) {
        this.userExchangeDocumentSelectList=data
      }
    },
    onExchangeDocument: function () {
      let viewerList = this.userExchangeDocumentSelectList.concat(this.documentExchangeViewerList)
      if (viewerList.length === 0) {
        this.ON_SHOW_TOAST({
          message: this.$t("document.selectDocumentRecipient"),
          styleType: "danger"
        });
      } else {
        let data = {
          viewList: this.removeDuplicateUserInList(viewerList),
          documentId: this.documentsPersonalUploadSelect.id
        }
        this.POST_USER_VIEW_DOCUMENT_EXCHANGE(data)
          .then(
            function () {
              this.isShow = false
              this.sendNotificationExchangeDocument()
            }.bind(this)
          )
      }
    },
    getUserViewDocumentExchange: function () {
      let filter = {
        params: {
          documentId: this.documentsPersonalUploadSelect.id,
        },
      };
      this.GET_USER_VIEW_DOCUMENT_EXCHANGE(filter)
        .then(
          function (res) {
            let data = res.data
            this.userExchangeDocumentSelectList = []
            this.documentExchangeViewerList = []
            data.forEach(userValue => {
              let full_name = userValue.last_name + " " + userValue.first_name;
              let item = {
                id: userValue.id,
                full_name: full_name,
                url_avatar:
                  userValue.url_avatar != null || userValue.url_avatar != ""
                    ? userValue.url_avatar
                    : null,
                is_group: userValue.is_group,
                email: userValue.email
              }
              this.documentExchangeViewerList.push(item)
              // remove user in select option
              let indexInSelectOption =  this.optionData.findIndex(x=>x.id===userValue.id)
              if (indexInSelectOption !== -1){
                this.optionData.splice(indexInSelectOption, 1);
              }
            })
          }.bind(this)
        )
    },
    removeCurrentDocumentViewer: function (viewer) {
      if (Object.keys(viewer).length > 0) {
        let index = this.documentExchangeViewerList.findIndex(x=> x.id===viewer.id && x.is_group===viewer.is_group)
        if (index != -1) {
          this.optionData.push(this.documentExchangeViewerList[index])
          this.documentExchangeViewerList.splice(index, 1);
        }
      }
    },
    removeDuplicateUserInList: function (arrayList) {
      let resultList = []
      arrayList.forEach(data => {
        let index = resultList.findIndex(x=>x.id===data.id && x.is_group===data.is_group)
        if (index === -1) {
          resultList.push(data)
        }
      })
      return resultList
    },
    getAllUserInEvent: function () {
      let eventId = sessionStorage.getItem('event_id')
      let user_filter = {
        params: {
          event: eventId,
          bandGroup: true,
          excludeCurrentUserLogin: true
        }
      };
      this.GET_USER_IN_EVENT_LIST(user_filter);
    },
    sendNotificationExchangeDocument: function () {
      let eventId = sessionStorage.getItem('event_id')
      let data = {
        event_id: eventId,
        full_name: this.GET_CURRENT_ACCOUNT.lastname + " " + this.GET_CURRENT_ACCOUNT.firstname,
        send_chairmain_and_secretary: true,
        action_code: TemplateCategoriesEnum.EXCHANGE_DOCUMENT,
        template_type: Enum.TemplateType.Notification,
      }
      this.SEND_NOTIFICATION_IN_MEETING(data)
    },
    ...mapActions([
      "GET_DOCUMENT_PERSONAL", 
      "CREATE_DOCUMENT", "ON_SHOW_TOAST", 
      "DELETE_DOCUMENT_PERSONAL", 
      "GET_USER_IN_EVENT_LIST", 
      "POST_USER_VIEW_DOCUMENT_EXCHANGE",
      "GET_USER_VIEW_DOCUMENT_EXCHANGE",
      "SEND_NOTIFICATION_IN_MEETING"
    ]),
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.add-resource {
  text-align: left; 
  padding-left: 15px;
  cursor: pointer;
  background-color: #E9F8ED; 
  width: 200px; 
  margin: auto; 
  padding: 8px 0px 8px 0px; 
  margin-top: 10px; 
  border-radius: 5px;
}
.general-calendar-title {
  text-align: left;
  background-color: #006AD4;
  padding: 10px 0px 10px 15px;
  color: #fff;
  font-size: 14px;
}
.document-personal-upload:hover {
  background: #e4e7e8;
}
.document-personal-upload-dropdown:hover {
  background: #e4e7e8;
}
.btn-submit {
    background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
    background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary), color-stop(0.7, $primary));
    border-radius: 5px;
}
</style>