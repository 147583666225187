<template>
  <div class="text-left">
    <div>
      <div class="general-calendar-title position-relative">
        <div class="row">
          <div class="col-12">
            <span class="pre-text">{{ $t("document.documentExchange") }}</span>
          </div>
        </div>
      </div>
      <div v-if="documentList.length == 0" style="text-align: center;">
        <span>{{ $t("scheduleMeeting.noDocument") }}</span>
      </div>
      <div v-else>
        <div
          v-for="(docmentItem, index) in documentList"
          :key="docmentItem + index"
          class="document-class"
        >
          <div
            :style="
              'cursor: pointer !important; margin-left: 15px;' +
                (index == 0
                  ? 'margin-top: 5px;'
                  : index == documentList.length - 1
                  ? 'margin-bottom: 5px'
                  : '')
            "
            :class="
              `justify-content-between d-flex ${index != 0 ? 'mt-2' : ''}`
            "
          >
            <div
              class="w-100 d-flex"
              @click="onSelectDocumentExchange(docmentItem)"
            >
              <div>
                <img
                  class="mr-2"
                  src="../../assets/icons/file-pdf.svg"
                  style="width: 16px; height: 35px;"
                />
              </div>
              <div>
                <span
                  class="pre-text"
                  :style="
                    documentId == docmentItem.id ? 'font-weight: bold' : ''
                  "
                  >{{ $t("document.documentName") }}:
                  {{ docmentItem.name }}</span
                >
                <p class="mb-0">
                  {{ $t("document.sender") }}: {{ docmentItem.sender }}
                </p>
                <p :style="`color: ${getStatus(docmentItem.documentExchangeStatus).color}`">
                  {{ $t(getStatus(docmentItem.documentExchangeStatus).text) }}</p>
                <!-- <p class="mb-0">
                {{docmentItem.userReceive}}
              </p> -->
              </div>
            </div>
            <div class="align-self-center mr-2">
              <img
                src="../../assets/sidebar_icon/bar.svg"
                alt=""
                data-toggle="dropdown"
              />
              <ul v-if="docmentItem.documentExchangeStatus === documentApproverStatus.IN_REVIEW"
                class="dropdown-menu float-right dropdown-menu-right"
                role="menu"
                style="max-height: 300px; overflow-y: auto;"
                aria-labelledby="dropdownMenuButton"
              >
                <li
                  class="mb-2 document-class-dropdown"
                  @click="onShowModalConfirmApproval(docmentItem)"
                >
                  <img
                    src="../../assets/sidebar_icon/valid.svg"
                    class="pl-2 pr-2"
                    alt=""
                  />
                  <span>{{ $t("document.role.approver") }}</span>
                </li>
                <li
                  class="document-class-dropdown"
                  @click="onShowReject(docmentItem)"
                >
                  <img
                    src="../../assets/sidebar_icon/Abort.svg"
                    class="pl-2 pr-2"
                    alt=""
                  />
                  <span>{{ $t("document.reject") }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <confirm-modal
      ref="confirmModal"
      :content="confirmModalContent"
      @onConfirm="confirmApprovalDocument()"
    ></confirm-modal>
    <b-modal
      v-model="isShow"
      :title="$t('common.confirm')"
      centered
      id="modal-center"
    >
      <div class="container">
        <div class="row p-2">
          <textarea
            class="form-control p-1"
            cols="45"
            rows="5"
            :placeholder="$t('document.reasonRejectDocumentExchange')"
            v-model="reasonReject"
          />
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right btn-submit ml-3"
            style="border: none;"
            @click="confirmReject()"
          >
            {{ $t("common.accept") }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="background-color: #fff; color: #000; border: none;"
            @click="isShow = false"
          >
            {{ $t("common.close") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import DocumentApproverStatus from "enum/documentApproverStatus";
import ConfirmModal from "Components/ConfirmModal";
import functionUtils from '../../utils/functionUtils';
import Enum from "enum/enum";
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'

export default {
  components: {
    ConfirmModal,
  },
  data: () => ({
    documentList: [],
    documentId: null,
    isShow: false,
    documentApproverStatus: DocumentApproverStatus,
    documentSelect: null,
    confirmModalContent: "",
    reasonReject: null,
    userList: [],
    groupList: [],
    documentOwnerId: null
  }),
  watch: {
    GET_DOCUMENT_EXCHANGE_TO_VIEWER_DATA: function() {
      this.documentList = [];
      let documentWaitingApproval = []
      let documentReject = []
      let documentApproval = []
      let data = this.GET_DOCUMENT_EXCHANGE_TO_VIEWER_DATA;
      data.forEach((element) => {
        let item = {
          id: element.id,
          sender:
            element.owner_info.last_name + " " + element.owner_info.first_name,
          documentUrl: element.url,
          name: element.name,
          documentExchangeStatus: element.document_exchange_status,
          ownerId: element.owner
        };
        if (element.document_exchange_status == this.documentApproverStatus.IN_REVIEW) {
          documentWaitingApproval.push(item)
        } else if(element.document_exchange_status == this.documentApproverStatus.UNAPPROVED) {
          documentReject.push(item)
        }else if (element.document_exchange_status == this.documentApproverStatus.APPROVED) {
          documentApproval.push(item)
        }
      });
      this.documentList=documentWaitingApproval.concat(documentApproval, documentReject)
      // select first document in array
      if (this.documentList.length > 0) {
        this.onSelectDocumentExchange(this.documentList[0])
      }
    },
  },
  computed: {
    ...mapGetters(["GET_DOCUMENT_EXCHANGE_TO_VIEWER_DATA"]),
  },
  created() {
    this.getDocumentExchangeToViewer();
  },
  methods: {
    onSelectDocumentExchange: function(document) {
      this.$emit("selectDocumentExchange", document);
      this.documentId = document.id;
    },
    getDocumentExchangeToViewer: function() {
      let eventId = sessionStorage.getItem("event_id");
      let filter = {
        params: {
          eventId: eventId,
        },
      };
      this.GET_DOCUMENT_EXCHANGE_TO_VIEWER(filter);
    },
    onShowModalConfirmApproval: function(document) {
      this.documentSelect = document;
      this.confirmModalContent = this.$t("document.approvalDocumentExchange");
      this.$refs.confirmModal.onShowModalConfirm();
      this.getUserViewDocumentExchange(document.id)
    },
    confirmApprovalDocument: function() {
      let data = {
        documentId: this.documentSelect && this.documentSelect.id,
        status: this.documentApproverStatus.APPROVED,
      };
      this.POST_DOCUMENT_EXCHANGE_TO_VIEWER_STATUS(data)
      .then(
        function () {
          this.getDocumentExchangeToViewer()
          this.sendNotificationApprovalDocument()
        }.bind(this))
      .catch(
        function() {
        }.bind(this)
      );
    },
    onShowReject: function(document) {
      this.reasonReject = null
      this.documentSelect = document;
      this.isShow = true;
      this.documentOwnerId = document.ownerId
    },
    confirmReject: function () {
      if (functionUtils.validateStringIsNull(this.reasonReject)){
        this.ON_SHOW_TOAST({
          message: this.$t("document.reasonRejectDocumentExchange"),
          styleType: "danger"
        });
      }else{
        let data = {
          documentId: this.documentSelect && this.documentSelect.id,
          status: this.documentApproverStatus.UNAPPROVED,
          reasonReject: this.reasonReject
        };
        this.POST_DOCUMENT_EXCHANGE_TO_VIEWER_STATUS(data)
        .then(
          function () {
            this.isShow = false
            this.getDocumentExchangeToViewer()
            this.sendNotificationRejectDocument()
          }.bind(this))
        .catch(
          function() {
          }.bind(this)
        );
      }
      
    },
    getStatus: function(statusValue){
      if (statusValue === this.documentApproverStatus.IN_REVIEW){
        return {text: 'document.status.awaitingApproval', color: '#006ad4'}
      } else if (statusValue === this.documentApproverStatus.UNAPPROVED){
        return {text: 'document.status.rejected', color: '#de253a'}
      } else if (statusValue === this.documentApproverStatus.APPROVED){
        return {text: 'document.status.approved', color: '#26ba50'}
      } else {
        return {text: '', color: ''}
      }
    },
    sendNotificationApprovalDocument: function() {
      let data = {
        event_id: sessionStorage.getItem("event_id"),
        template_type: Enum.TemplateType.Notification,
        action_code: TemplateCategoriesEnum.EXCHANGE_DOCUMENT_APPROVAL,
        notification_type_code: Enum.NotificationType.exchangeDocumentApproval,
        user_list: this.userList,
        group_list: this.groupList,
        document_name: this.documentSelect && this.documentSelect.name
      };
      this.SEND_NOTIFICATION_IN_MEETING(data);
    },
    sendNotificationRejectDocument: function() {
      let data = {
        event_id: sessionStorage.getItem("event_id"),
        template_type: Enum.TemplateType.Notification,
        action_code: TemplateCategoriesEnum.EXCHANGE_DOCUMENT_REJECT,
        notification_type_code: Enum.NotificationType.exchangeDocumentReject,
        user_id: this.documentOwnerId,
        document_name: this.documentSelect && this.documentSelect.name
      };
      this.SEND_NOTIFICATION_IN_MEETING(data);
    },
    getUserViewDocumentExchange: function(documentId) {
      let filter = {
        params: {
          documentId: documentId,
        },
      };
      this.GET_USER_VIEW_DOCUMENT_EXCHANGE(filter).then(
        function(res) {
          let data = res.data;
          this.userList = []
          this.groupList = []
          data.forEach((userValue) => {
            if (userValue.is_group) {
              this.groupList.push(userValue.id)
            } else {
              this.userList.push(userValue.id)
            }
          });
        }.bind(this)
      );
    },
    ...mapActions([
      "ON_SHOW_TOAST",
      "GET_DOCUMENT_EXCHANGE_TO_VIEWER",
      "POST_DOCUMENT_EXCHANGE_TO_VIEWER_STATUS",
      "SEND_NOTIFICATION_IN_MEETING",
      "GET_USER_VIEW_DOCUMENT_EXCHANGE"
    ]),
  },
};
</script>
<style lang="scss" scoped>
@import "../../style/color.scss";
.general-calendar-title {
  text-align: left;
  background-color: #006ad4;
  padding: 10px 0px 10px 15px;
  color: #fff;
  font-size: 14px;
}
.document-class:hover {
  background: #e4e7e8;
}
.document-class-dropdown:hover {
  background: #e4e7e8;
}
.btn-submit {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary), color-stop(0.7, $primary));
  border-radius: 5px;
}
</style>
