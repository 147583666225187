<template>
  <div>
    <b-modal
      v-model="isShow"
      :title="$t('document.btnPersonalToGeneral')"
      centered
      id="modal-center"
    >
      <div class="container">
        <div>
          <!-- Enter title document -->
          <div>
            <span style="font-size: small;">{{
              $t("document.documentTitle")
            }}</span>
            <textarea
              v-model="documentTitle"
              class="form-control mt-2"
              style="font-size: small;"
              :placeholder="$t('document.enterTitle')"
              rows="2"
              v-bind:class="{
                'form-control': true,
                'is-invalid': !validRequire(documentTitle) && fieldBlured,
              }"
              v-on:blur="fieldBlured = true"
            ></textarea>
            <div class="invalid-feedback" style="text-align: left;">
              {{ $t("document.fieldRequire.documentTitle") }}
            </div>
          </div>
          <!-- Security and need approval -->
          <div class="row mt-3">
            <div class="col-3">
              <span style="font-size: small;">{{
                $t("document.security")
              }}</span>
            </div>
            <div class="col-6">
              <div
                v-show="security"
                style="display: inline-block; cursor: pointer;"
                @click="setSecurity()"
              >
                <i class="far fa-check-square mr-2" style="color: green;"></i>
              </div>
              <div
                v-show="!security"
                style="display: inline-block; cursor: pointer;"
                @click="setSecurity()"
              >
                <i class="far fa-square mr-2" style="color: green;"></i>
              </div>
              <span v-if="security" style="font-size: small;">
                {{ $t("document.yes") }}
              </span>
              <span v-else style="font-size: small;">
                {{ $t("document.no") }}
              </span>
            </div>
          </div>
        </div>
        <div class="mt-2 mb-2" style="font-size: small; font-weight: bold;">
          {{ $t('document.chooseSession') }}
        </div>
        <div style="height: 200px; overflow-y: scroll; display: block;">
          <div
            v-for="(eventSession, index) in eventSessions"
            :key="eventSession + index"
          >
            <div style="display: flex;">
              <!-- Title -->
              <div style="width: 90%;">
                <p style="font-size: small;">
                  {{ index + 1 }}. {{ eventSession.title }}
                </p>
              </div>
              <div style="width: 5%; cursor: pointer; text-align: right;">
                <input
                  v-model="eventSessionSelected.id"
                  type="radio"
                  name="optradio"
                  :value="eventSession.id"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2 mb-2" style="font-size: small; font-weight: bold;">
            <!-- <span class="mb-2" style="font-size: small;">{{
              $t("document.messageForDocumentCreator")
            }}</span> -->
            <label for>{{$t('document.assignDocumentViewer')}}</label>
        </div>
        <div>
          <VueMutiselect
            :optionData="userInEventList"
            :customLabel="customLabel"
            :labelText="labelTextValue"
            :trackByText="trackByTextValue"
            :placeHolder="$t('mission.selectPerson')"
            @select="getAssignerSelect"
            ref="VueMutiselect"
          ></VueMutiselect>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="sm"
            class="float-right btn-submit ml-3"
            style="border: none;"
            @click="onConfirm()"
          >
            {{ $t("common.accept") }}
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            style="background-color: #fff; color: #000; border: none;"
            @click="isShow = false"
          >
            {{ $t("common.close") }}
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import VueMutiselect from "Components/VueMutiselect";

export default {
  components:{
    VueMutiselect
  },
  data: () => ({
    fieldBlured: false,
    isShow: false,
    eventSessions: [],
    eventSessionSelected: {
      id: null,
      title: null,
    },
    documentPersonalSelected: {
      id: null,
    },
    security: false,
    documentTitle: "",

    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    userInEventList: [],
    userSelected: []
  }),
  computed: {
    ...mapGetters(["GET_EVENT_SESSIONS_BY_ROLE_DATA", "GET_USER_IN_EVENT_LIST_DATA"]),
  },
  watch: {
    GET_EVENT_SESSIONS_BY_ROLE_DATA: function() {
      let data = this.GET_EVENT_SESSIONS_BY_ROLE_DATA;
      this.eventSessions = [];
      for (let i = 0, size = data.length; i < size; i++) {
        let obj = {
          id: data[i].id,
          title: data[i].title,
        };
        this.eventSessions.push(obj);
      }
    },
    GET_USER_IN_EVENT_LIST_DATA() {
      this.userInEventList = [];
      this.GET_USER_IN_EVENT_LIST_DATA.forEach(data => {
        let full_name = data.lastname + " " + data.firstname;
        let item = {
          id: data.id,
          full_name: full_name,
          url_avatar:
            data.url_avatar != null || data.url_avatar != ""
              ? data.url_avatar
              : null,
          is_group: data.is_group
        };
        this.userInEventList.push(item);
      });
    },
  },
  methods: {
    /**
     * Set security
     */
    setSecurity: function() {
      this.security = !this.security;
    },
    /**
     * Valid require
     */
    validRequire: function(fieldRequire) {
      if (fieldRequire != "" && fieldRequire != null) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * Show modal
     */
    onShowModal: function(documentPersonalSelectedId, name) {
      let eventId = sessionStorage.getItem("event_id");
      this.documentPersonalSelected.id = documentPersonalSelectedId;
      this.documentTitle = name
      let filter = {
        params: {
          eventId: eventId,
        },
      };
      this.GET_EVENT_SESSION_BY_ROLE(filter);
      let user = {
        params: {
          event: eventId,
          bandGroup: true
        }
      }
      this.GET_USER_IN_EVENT_LIST(user);
      this.isShow = true;
    },
    /**
     * Confirm
     */
    onConfirm: function() {
      this.fieldBlured = true;
      if (this.validRequire(this.documentTitle)) {
        
        let filter = {
          documentId: this.documentPersonalSelected.id,
          eventSessionId: this.eventSessionSelected.id,
          documentTitle: this.documentTitle,
          security: this.security
        };
        if (this.userSelected.length > 0){
          filter['viewer'] = this.userSelected
        }
        this.SWITCH_DOCUMENT_FROM_PERSONAL_TO_GENERAL(filter).then(
          function() {
            this.documentTitle = ''
            this.security = false
            this.eventSessionSelected.id = null
            this.$emit("switchDocumentSuccess");
            this.isShow = false;
            this.ON_SHOW_TOAST({
              message: this.$t("common.success"),
              styleType: "success",
            });
          }.bind(this)
        );
      }
    },
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    getAssignerSelect(data) {
      this.userSelected = [];
      data.forEach(item => {
        this.userSelected.push({id: item.id, is_group: item.is_group});
      });
    },
    ...mapActions([
      "ON_SHOW_TOAST",
      "GET_EVENT_SESSION_BY_ROLE",
      "SWITCH_DOCUMENT_FROM_PERSONAL_TO_GENERAL",
      "GET_USER_IN_EVENT_LIST"
    ]),
  },
};
</script>
