<template>
  <div class="vh-100">
    <!-- Tab for general -->
    <div v-if="documentGeneral == DOCUMENT_TAB_TYPE">
      <!-- START Function for cecretary, supporter, host -->
      <div v-if="isSecretaryRole() || isChairmanRole()" style="padding: 15px 0 15px 0">
        <!-- Add content -->
        <div class="add-resource" @click="addEventSession()">
          <i class="fas fa-plus icon-plus-style"></i>
          <span>{{ $t('document.addContent') }}</span>
        </div>
        <!-- Document status -->
        <div v-if="isSecretaryRole() || isChairmanRole()" class="add-resource" style="padding-top: 15px; display: flex;">
          <!-- All -->
          <div class="w-50 left-align" @click="filterDocumentStatus(null)">
            <div style="display: inline-block;">
              <div v-show="documentStatus == null">
                <i class="fas fa-check-circle" style="color: #26BA50"></i>
              </div>
              <div v-show="documentStatus != null">
                <i class="far fa-circle" style="color: #26BA50"></i>
              </div>
            </div>
            <div style="position: relative; display: inline-block;">
              <span class="ml-2 meeting-font-large">{{ $t('document.all') }}</span>
              <span class="meeting-font-10px all-badge">{{ numberOfAllDocument }}</span>
            </div>
          </div>
          <!-- Waiting approve -->
          <div class="w-50 left-align" @click="filterDocumentStatus(awaitingApprovalStatus)">
            <div style="display: inline-block;">
              <div v-show="documentStatus == awaitingApprovalStatus">
                <i class="fas fa-check-circle" style="color: #26BA50"></i>
              </div>
              <div v-show="documentStatus != awaitingApprovalStatus">
                <i class="far fa-circle" style="color: #26BA50"></i>
              </div>
            </div>
            <div style="position: relative; display: inline-block;">
              <span class="ml-2 meeting-font-large">{{ $t('document.status.awaitingApproval') }}</span>
              <span class="meeting-font-10px waiting-approve-badge">{{ numberOfWaitingApproveDocument }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="max-height: 100vh; overflow-y: auto; overflow-x: hidden;">
        <!-- END Function for cecretary, supporter, host -->
        <div v-for="(meetingResource, index) in meetingResources" :key="meetingResource + index">
          <!-- Meeting description -->
          <div class="general-calendar-title position-relative" style="cursor: pointer;" @click="onEventSession(meetingResource, index)">
            <div class="row">
              <div class="col-10">
                <span :class="indexEventSessionSelected == index ? 'select pre-text' : 'not-select pre-text'">{{ meetingResource.description }}</span>
              </div>
              <div class="col-2 align-self-center" data-toggle="dropdown">
                <img class="float-right" src="../assets/icons/list-ul.svg" style="margin: 0px 15px 0 0; width: 5px;">
                <ul
                  v-if="isSecretaryRole() || isChairmanRole()"
                  class="dropdown-menu float-right dropdown-menu-right"
                  role="menu"
                  style="max-height: 300px; overflow-y: auto;"
                  aria-labelledby="dropdownMenuButton"
                >
                  <li class="document-class-dropdown py-1" @click="onDeleteContent(meetingResource)">
                    <img src="../assets/icons/trash-alt-solid.svg" class="px-2" alt="">
                    <span >{{$t('common.delete')}}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- <div style="width: 50%; height: 2px; background-color: #006AD4;"></div> -->
          <div v-show="meetingResource.documents.length <= 0">
            <div style="padding: 15px;">
              <span style="font-weight: bold; font-size: normal;">{{ $t('document.noDocumentForThisContent') }}</span>
              <div v-if="!isNull(meetingResource.users_in_event_session) || isSecretaryRole() || isChairmanRole()" @click="addDocumentInEventSession(meetingResource)" class="add-resource" style="background-color: #E9F8ED; width: 200px; margin: auto; padding: 8px 0px 8px 0px; margin-top: 10px; border-radius: 5px;">
                <div style="text-align: center;">
                  <i class="fas fa-plus icon-plus-style" style="color: #26BA50 !important;"></i>
                  <span style="color: #26BA50;">{{ $t('document.addDocument') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-show="meetingResource.documents.length > 0">
            <div v-for="(document, index) in meetingResource.documents" :key="document + index">
              <!-- Document info -->
              <div :class="['document-item', document.selected ? 'document-item-active' : '']" @click="onSelectDocument(document, index)">
                <div style="display: flex; justify-content: space-between;">
                  <!-- Logo document -->
                  <div style="margin-left: 15px; align-self: flex-start;">
                    <img
                      src="../assets/icons/file-pdf.svg"
                      style="width: 16px; height: 35px;"
                    />
                  </div>
                  <!-- Document content -->
                  <div style="width: 100%; margin-left: 15px; margin-right: 15px;">
                    <div style="text-align: left;">
                      <span class="pre-text" style="font-size: 14px;">{{ document.name }}</span>
                    </div>
                    <div class="pt-2 pb-2" style="text-align: right; font-size: small;">
                      <span :class="[document.security ? 'mr-2' : '', 'document-status', document.status == approvedStatus || document.status == summaryStatus ? 'success' : (document.status == rejectedStatus ? 'primary' : 'warning') ]">{{ $t(showStatus(document.status)) }}</span>
                      <span v-if="document.security" class="document-security">{{ $t('document.security') }}</span>
                    </div>
                  </div>
                  <!-- Arrow -->
                  <div style="align-self: center; margin-right: 15px;">
                    <div style="display: inline-block;" v-show="!document.selected">
                      <i class="fas fa-chevron-right" style="color: #0097A7"></i>
                    </div>
                    <div style="display: inline-block;" v-show="document.selected">
                      <i class="fas fa-chevron-down" style="color: #0097A7"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="document.selected && !isNull(document.reviewer) && GET_CURRENT_ACCOUNT.id != document.owner.id && !isSecretaryRole() && !isChairmanRole()" style="padding: 10px 47px 10px 47px; border-bottom: 1px solid #e5e5e5;">
                <!-- Uploader -->
                <div class="row text-left mb-2">
                  <div class="col-5">
                    <span class="meeting-font-medium">
                      {{ $t('document.uploader') }}
                    </span>
                  </div>
                  <div class="col-7 meeting-font-medium">
                    {{ !isNull(document.owner) ? (document.owner.last_name + ' ' + document.owner.first_name) : '' }}
                  </div>
                </div>
                <!-- Time -->
                <div class="row text-left mb-2">
                  <div class="col-5">
                    <span class="meeting-font-medium">
                      {{ $t('document.time') }}
                    </span>
                  </div>
                  <div class="col-7 meeting-font-medium">
                    {{ document.date_created }}
                  </div>
                </div>
                <!-- Approver -->
                <div class="row text-left mb-2">
                  <div class="col-5">
                    <span class="meeting-font-medium">
                      {{ document.status == approvedStatus ? $t('document.approver') : (document.status == rejectedStatus ? $t('document.notApprover') : $t('document.approver')) }}
                    </span>
                  </div>
                  <div v-if="!isNull(document.approver)" class="col-7 meeting-font-medium">
                    <span>{{ document.approver ? document.approver.last_name + ' ' + document.approver.first_name : '' }}</span>
                  </div>
                </div>
                <!-- Action document -->
                <div v-if="!document.security && document.status == approvedStatus" class="mt-3" style="align-self: center; text-align: left;">
                  <button
                    type="submit"
                    class="btn-download-document meeting-font-medium btn mr-3"
                    style="color: #ffffff; padding: 4px 15px 4px 15px;"
                    @click.prevent="downloadDocument(document.url)"
                  >
                    {{ $t('document.downloadDocument') }}
                  </button>
                  <span class="meeting-font-medium" style="cursor: pointer;" @click="reportError()">{{ $t('document.reportError') }}</span>
                </div>
              </div>
              <div v-else-if="document.selected" class="text-left" style="padding: 10px 47px 10px 47px; border-bottom: 1px solid #e5e5e5">
                <!-- Uploader -->
                <div>
                  <span class="meeting-font-medium">{{ $t('document.uploader') }}: {{ document.owner ? document.owner.last_name + ' ' + document.owner.first_name : '' }}</span>
                </div>
                <!-- Approver -->
                <div class="mt-2" v-if="!isNull(document.approver)">
                  <span class="meeting-font-medium">{{ document.status == approvedStatus ? $t('document.approver') : $t('document.notApprover') }} : {{ document.approver ? document.approver.last_name + ' ' + document.approver.first_name : '' }}</span>
                </div>
                <div>
                  <span class="mt-2 meeting-font-medium" style="display: block;">{{ $t('mission.time') }}: {{ (document.status == approvedStatus || document.status == rejectedStatus) ? document.date_updated : document.date_created }}</span>
                </div>
                <!-- Action document -->
                <div v-if="!document.security" class="mt-3" style="align-self: center; text-align: center;">
                  <button
                    type="submit"
                    class="btn-download-document meeting-font-medium btn mr-3"
                    style="color: #ffffff; padding: 4px 15px 4px 15px;"
                    @click.prevent="downloadDocument(document.url)"
                  >
                    {{ $t('document.downloadDocument') }}
                  </button>
                  <span class="meeting-font-medium" style="cursor: pointer;" @click="reportError()">{{ $t('document.reportError') }}</span>
                </div>
              </div>
            </div>
            <div v-if="!isNull(meetingResource.users_in_event_session) || isSecretaryRole() || isChairmanRole()" @click="addDocumentInEventSession(meetingResource)" class="add-resource mb-2" style="background-color: #E9F8ED; width: 200px; margin: auto; padding: 8px 0px 8px 0px; margin-top: 10px; border-radius: 5px;">
              <div style="text-align: center;">
                <i class="fas fa-plus icon-plus-style" style="color: #26BA50 !important;"></i>
                <span style="color: #26BA50;">{{ $t('document.addDocument') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="documentPersonal == DOCUMENT_TAB_TYPE" class="pb-15px pl-0 pr-0">
      <personalTab @selectDocumentPersonal="onSelectDocumentPersonal" @addDocumentPersonal="onAddDocumentPersonal"></personalTab>
    </div>
    <div v-else class="pb-15px pl-0 pr-0">
      <documentExchangeTab @selectDocumentExchange="onSelectDocumentExchange"/>
    </div>
    <loading-bar :isLoading="isLoading"></loading-bar>
    <snack-bar
      v-for="snackbar in GET_SNACK_BAR"
      :key="snackbar.id"
      :item="snackbar"
    ></snack-bar>
    <confirm-modal
      ref="confirmDeleteEventSessionModal"
      :content="deleteEventSessionContent"
      @onConfirm="confirmDeleteEventSession()"
    ></confirm-modal>
  </div>
</template>
<script>
import TabType from "enum/tabType";
import { mapActions, mapGetters } from "vuex";
import dateUtils from "utils/dateUtils";
// import Avatar from "vue-avatar";
// import DocumentApproverStatus from 'enum/documentApproverStatus'
import DocumentStatus from 'enum/documentStatus'
import functionUtils from 'utils/functionUtils'
import DocumentTabType from 'enum/documentTabType'
import PersonalTab from 'Views/Meeting/PersonalTab'
import AddDocumentType from 'enum/addDocumentType'
import documentExchangeTab from 'Views/Meeting/DocumentExchangeTab'
import ConfirmModal from "Components/ConfirmModal";
export default {
  props: {
    type: {
      type: Number,
      default: () => {
        return 0;
      },
    },
    titleList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  components: {
    // avatar: Avatar,
    personalTab: PersonalTab,
    documentExchangeTab,
    ConfirmModal
  },
  data: () => ({
    tabType: TabType,
    eventSessions: [],
    isLoading: false,
    // document
    indexDocumentSelect: -1,
    indexEventSessionSelected: -1,
    documentList: [],
    documentStatus: null,
    isExistEventSessionFavorite: false,
    meetingResources: [],
    numberOfAllDocument: 0,
    numberOfWaitingApproveDocument: 0,
    awaitingApprovalStatus: DocumentStatus.AWAITING_APPROVAL,
    approvedStatus: DocumentStatus.APPROVED,
    rejectedStatus: DocumentStatus.REJECTED,
    summaryStatus: DocumentStatus.SUMMARY,
    documentGeneral: DocumentTabType.GENERAL,
    documentPersonal: DocumentTabType.PERSONAL,
    documentTabType: DocumentTabType.GENERAL,
    documentExchange: DocumentTabType.DOCUMENT_EXCHANGE_APPROVAL,
    eventSessionSelect: null,
    deleteEventSessionContent: ''
  }),
  computed: {
    ...mapGetters([
      "GET_EVENT_SESSIONS_DATA",
      "GET_MEETING_RESOURCE_IN_EVENT_DATA",
      "GET_DOCUMENT_DATA",
      "GET_SNACK_BAR",
      "GET_CURRENT_ACCOUNT",
      "DOCUMENT_TAB_TYPE"
    ]),
  },
  watch: {
    DOCUMENT_TAB_TYPE: function () {
      if (this.DOCUMENT_TAB_TYPE == DocumentTabType.GENERAL) {
        if (this.meetingResources.length != 0) {
          this.indexEventSessionSelected = 0
          this.$emit("eventSelect", this.meetingResources[0]);
        }
      } else if (this.DOCUMENT_TAB_TYPE == DocumentTabType.PERSONAL) {
        this.$emit("personalTabSelect");
      } else {
        this.$emit("documentExchangeTab")
      }
    },
    GET_MEETING_RESOURCE_IN_EVENT_DATA: function () {
      let meetingResourcesInEvent = this.GET_MEETING_RESOURCE_IN_EVENT_DATA
      this.meetingResources = []
      // Set default number
      this.numberOfAllDocument = 0
      this.numberOfWaitingApproveDocument = 0
      for (let i = 0, size = meetingResourcesInEvent.length; i < size; i++) {
        this.numberOfAllDocument += meetingResourcesInEvent[i].documents.length
        let meetingResourceObj = {
          id: meetingResourcesInEvent[i].id,
          description: meetingResourcesInEvent[i].title,
          documents: this.getDocuments(meetingResourcesInEvent[i].documents),
          selected: false,
          users_in_event_session: meetingResourcesInEvent[i].users_in_event_session
        }
        this.meetingResources.push(meetingResourceObj)
      }
      if (this.meetingResources.length != 0 && this.DOCUMENT_TAB_TYPE == DocumentTabType.GENERAL) {
        this.indexEventSessionSelected = 0
        this.$emit("eventSelect", this.meetingResources[0]);
      }
      this.isLoading = false
      if (this.numberOfAllDocument < 0) {
        this.numberOfAllDocument = 0
      }
    }
  },
  methods: {
    /**
     * Add document personal
     */
    onAddDocumentPersonal: function (addDocumentType) {
      this.$emit('addDocumentInEventSession', addDocumentType)
    },
    /**
     * Select document personal
     */
    onSelectDocumentPersonal: function (document) {
      this.$emit('selectDocumentPersonal', document)
    },
    /**
     * Filter document by awaiting approval status
     */
    filterDocumentByAwaitingApprovalStatus: function (data) {
      return data.status == DocumentStatus.AWAITING_APPROVAL
    },
    /**
     * Filter document by status
     */
    filterDocumentStatus: function (documentStatus) {
      this.documentStatus = documentStatus
      if (functionUtils.isNull(documentStatus)) {
        let meetingResourcesInEvent = this.GET_MEETING_RESOURCE_IN_EVENT_DATA
        this.meetingResources = []
        // Set default number
        this.numberOfAllDocument = 0
        this.numberOfWaitingApproveDocument = 0
        for (let i = 0, size = meetingResourcesInEvent.length; i < size; i++) {
          this.numberOfAllDocument += meetingResourcesInEvent[i].documents.length
          let meetingResourceObj = {
            id: meetingResourcesInEvent[i].id,
            description: meetingResourcesInEvent[i].title,
            documents: this.getDocuments(meetingResourcesInEvent[i].documents),
            selected: false,
            users_in_event_session: meetingResourcesInEvent[i].users_in_event_session
          }
          this.meetingResources.push(meetingResourceObj)
        }
        if (this.meetingResources.length != 0 && this.DOCUMENT_TAB_TYPE == DocumentTabType.GENERAL) {
          this.indexEventSessionSelected = 0
          this.$emit("eventSelect", this.meetingResources[0]);
        }
        this.isLoading = false
      } else {
        for (let i = 0, size = this.meetingResources.length; i < size; i++) {
          this.meetingResources[i].documents = this.meetingResources[i].documents.filter(this.filterDocumentByAwaitingApprovalStatus) 
        }
      }
      if (this.numberOfAllDocument < 0) {
        this.numberOfAllDocument = 0
      }
    },
    /**
     * Download document
     */
    downloadDocument (url) {
      if (this.isNull(url) || functionUtils.isEmptyString(url)) {
        this.ON_SHOW_TOAST({
          message: this.$t("scheduleMeeting.noDocument"),
          styleType: "danger"
        })
      } else {
        window.open(url, '_blank')
      }
    },
    /**
     * Check has support role
     */
    isSupportRole: function () {
      return functionUtils.isSupportRole(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Check has support role
     */
    isSecretaryRole: function () {
      return functionUtils.isSecretaryRole(this.GET_CURRENT_ACCOUNT)
    },
    /**
     * Check has chairman role
     */
    isChairmanRole: function () {
      return functionUtils.isChairmanRole(this.GET_CURRENT_ACCOUNT)
    },
    // Check null
    isNull: function (data) {
      return functionUtils.isNull(data)
    },
    /**
     * Add document success
     */
    addDocumentSuccess: function (addDocumentType) {
      if (addDocumentType == AddDocumentType.PERSONAL) {
        let event_id = sessionStorage.getItem("event_id");
        let filter = {
          params: {
            eventId: event_id,
          },
        };
        this.GET_DOCUMENT_PERSONAL(filter);
      } else {
        let event_id = sessionStorage.getItem("event_id");
        let filter = {
          params: {
            eventId: event_id,
          },
        };
        this.isLoading = true;
        this.GET_MEETING_RESOURCE_IN_EVENT(filter)
          .then(
            function() {
              // Todo
            }.bind(this)
          )
          .catch(
            function() {
              this.isLoading = false;
            }.bind(this)
          );
      }
    },
    /**
     * Add document in event session
     */
    addDocumentInEventSession: function (meetingResouce) {
      this.$emit('addDocumentInEventSession', meetingResouce)
    },
    /**
     * Add event session
     */
    addEventSession: function () {
      this.$emit('addEventSession')
    },
    /**
     * Report error
     */
    reportError: function () {
      // Todo
    },
    /**
     * Get documents
     */
    getDocuments: function (documents) {
      // Remove some document is null
      for (let j = 0, documentSize = documents.length; j < documentSize; j++) {
        if (this.isNull(documents[j])) {
          this.numberOfAllDocument -= 1
          documents.splice(j, 1)
        }
      }
      let newDocuments = []
      for (let i = 0, size = documents.length; i < size; i++) {
        if (documents[i] !== null) {
          if (documents[i].status == DocumentStatus.AWAITING_APPROVAL) {
            this.numberOfWaitingApproveDocument += 1
          }
          let documentObj = {
            date_created: dateUtils.formatDate(documents[i].date_created, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, dateUtils.STATIC_FORMAT_TIME_DATE),
            date_updated: dateUtils.formatDate(documents[i].date_updated, dateUtils.STATIC_FORMAT_DATE_TIME_ZONE, dateUtils.STATIC_FORMAT_TIME_DATE),
            id: documents[i].id,
            name: documents[i].name,
            url: documents[i].url,
            posterUrl: documents[i].poster_url ? documents[i].poster_url : "https://event.avatarnext.com/static/images/noposter.png",
            selected: false,
            owner: documents[i].owner,
            security: documents[i].security,
            status: documents[i].status,
            approver: documents[i].approver,
            reviewer: documents[i].reviewer,
            hasViewCurrentDocument: documents[i].has_view_current_document,
            currentDigitalSigner: documents[i].current_digital_signer,
          }
          newDocuments.push(documentObj)
        }
      }
      return newDocuments
    },
    /**
     * Show document status
     */
    showStatus: function (status) {
      if (status == DocumentStatus.AWAITING_APPROVAL) {
        return 'document.status.awaitingApproval'
      } else if (status == DocumentStatus.COMPLETE) {
        return 'document.status.approved'
      } else if (status == DocumentStatus.APPROVED || status == DocumentStatus.SUMMARY) {
        return 'document.status.approved'
      } else if (status == DocumentStatus.REJECTED) {
        return 'document.status.rejected'
      } else {
        return ''
      }
    },
    /**
     * On event session
     */
    onEventSession: function(eventSession, index) {
      if (this.type == this.tabType.MEETING_RESOURCE) {
        this.indexEventSessionSelected = index
      }
      this.$emit("eventSelect", eventSession);
      this.$emit("closeNote")
    },
    /**
     * Select document
     */
    onSelectDocument: function(documentData, index) {
      if (this.type == this.tabType.MEETING_RESOURCE) {
        documentData.selected = true
        // Get all meeting resource
        for (let j = 0, meetingResourceSize = this.meetingResources.length; j < meetingResourceSize; j++) {
          // Get all document of all meeting resource
          // Set selected is false if not is document specified
          for (let k = 0, documentSize = this.meetingResources[j].documents.length; k < documentSize; k++) {
            if (documentData.id != this.meetingResources[j].documents[k].id) {
              this.meetingResources[j].documents[k].selected = false
            }
          }
        }
      } else {
        this.indexDocumentSelect = index;
      }
      this.$emit("document", documentData);
      this.$emit("closeNote")
    },
    onSelectDocumentExchange: function (document) {
      this.$emit('onSelectDocumentExchangeData', document)
    },
    onDeleteContent: function (data) {
      this.eventSessionSelect = data
      this.deleteEventSessionContent = this.$t('document.confirmDeleteEventSessionContent', {0: data.description})
      this.$refs.confirmDeleteEventSessionModal.onShowModalConfirm();
    },
    confirmDeleteEventSession: function () {
      let eventSessionId = this.eventSessionSelect && this.eventSessionSelect.id
      let event_id = sessionStorage.getItem("event_id");
      let data = {
        id: eventSessionId,
        event_id: event_id
      }
      this.DELETE_EVENT_SESSION(data)
      .then(
        function () {
          
          let filter = {
            params: {
              eventId: event_id,
            },
          };
          this.GET_MEETING_RESOURCE_IN_EVENT(filter)
        }.bind(this))
      .catch(
        function() {
        }.bind(this)
      );
    },
    ...mapActions([
      "GET_EVENT_SESSION",
      "GET_MEETING_RESOURCE_IN_EVENT",
      "GET_DOCUMENT_LIST",
      "SET_FAVORITE_USER_IN_EVENT_SESSION",
      "ON_SHOW_TOAST",
      "GET_DOCUMENT_PERSONAL",
      "DELETE_EVENT_SESSION"
    ]),
  },
  mounted() {
    let event_id = sessionStorage.getItem("event_id");
    let filter = {
      params: {
        eventId: event_id,
      },
    };
    if (this.type == TabType.MEETING_RESOURCE) {
      this.isLoading = true;
      this.GET_MEETING_RESOURCE_IN_EVENT(filter)
        .then(
          function() {
            // Todo
          }.bind(this)
        )
        .catch(
          function() {
            this.isLoading = false;
          }.bind(this)
        );
    }
  },
};
</script>
<style scoped lang="scss">
@import "../style/color.scss";
.bell-star-style {
  color: $primary;
}
.general-calendar-content {
  padding: 10px 10px 10px 10px;
  margin: 0 10px 0 10px;
}
.general-calendar-title {
  text-align: left;
  background-color: #006AD4;
  padding: 10px 0px 10px 15px;
  color: #fff;
  font-size: 14px;
}
/deep/ .card-header {
  background-color: transparent !important;
  border: none;
  padding-top: 0 !important;
  margin-top: 0 !important;
}
/deep/ .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: transparent;
  color: $primary;
  border-bottom: 2px solid #ff6e6e;
  border-radius: 0;
}
/deep/ .nav-pills .nav-link {
  color: $primary;
}
/deep/ .tab-content > .tab-pane {
  padding: 0 !important;
}
/deep/ .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  padding-top: 0;
}
/deep/ .nav-pills .nav-link,
.nav-pills .show > .nav-link {
  padding-top: 0;
}
.select {
  font-weight: bold;
}
.not-select {
  font-weight: normal;
}
.pointer {
  cursor: pointer;
}
.btn-download-document {
  background: -moz-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -o-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -ms-linear-gradient(bottom, $light-primary 100%, $primary 100%);
  background: -webkit-gradient(linear,left bottom,left top,color-stop(0.3, $light-primary), color-stop(0.7, $primary));
  border-radius: 5px;
}
.add-resource {
  text-align: left; 
  padding-left: 15px;
  cursor: pointer;
}
.icon-plus-style {
  color: green; 
  margin-right: 10px; 
  font-size: large;
}
.document-status {
  font-size: 12px; 
  padding: 5px 10px 5px 10px; 
  border-radius: 20px; 
  background-color: #fff;
}
.warning {
  color: $warning;
  border: 1px solid $warning !important;
}
.success {
  color: $success !important;
  border: 1px solid $success !important;
}
.primary {
  color: $primary !important;
  border: 1px solid $primary !important;
}
.document-security {
  color: $primary-500; 
  padding: 5px 10px 5px 10px; 
  border: 1px solid $primary-500;
  border-radius: 20px; 
  background-color: #fff;
}
.all-badge {
  position: absolute; 
  top: -10px; 
  right: -20px; 
  padding: 0px 5px 0px 5px; 
  background-color: #fff; 
  color: $primary-500;
  border-radius: 10px; 
  border: 1px solid $primary-500;
}
.waiting-approve-badge {
  position: absolute; 
  top: -10px; 
  right: -20px; 
  padding: 0px 5px 0px 5px; 
  background-color: $primary-500;
  color: #fff; 
  border-radius: 10px;
}
.pt-15px {
  padding-top: 15px;
}
.pb-15px {
  padding-bottom: 15px;
}
.document-item {
  cursor: pointer; 
  border-bottom: 1px solid #e5e5e5; 
  padding-top: 10px; 
  padding-bottom: 6px;
}
.document-item-active {
  background-color: #ECEFF0;
}
.document-class-dropdown:hover {
  background: #e4e7e8;
}
</style>
