<template>
  <div>
    <div
      style="background-color: #ECEFF0; border-left: 1px solid #7D7D7D; position: relative;"
    >
      <div style="overflow-y: auto; height: 350px;">
        <div
          v-for="(documentNote, index) in documentNotes"
          :key="documentNote + index"
        >
          <div
            class="text-left"
            style="display: flex; padding: 20px !important; width: 100%"
            v-if="GET_CURRENT_ACCOUNT.id != documentNote.userId"
          >
            <!-- Avatar -->
            <div>
              <avatar
                v-if="documentNote.avatar"
                :src="documentNote.avatar"
                backgroundColor="#fff"
                color="#000"
                :size="40"
                style="align-self: start;"
              ></avatar>
              <avatar
                v-else
                :username="documentNote.fullName"
                backgroundColor="#fff"
                color="#000"
                :size="40"
                style="align-self: start;"
              ></avatar>
            </div>
            <!-- Message -->
            <div
              style="align-self: center; margin-right: 10px; padding: 5px 10px 5px 10px; background-color: #fff; border-radius: 5px; margin-left: 10px;"
            >
              <span
                style="display: block; color: #7D7D7D;"
                class="meeting-font-medium"
                >{{ documentNote.fullName }}</span
              >
              <span class="meeting-font-medium">{{
                documentNote.description
              }}</span>
            </div>
          </div>
          <div
            class="text-right mt-2"
            style="padding: 10px 20px 10px 20px !important; float: right; display: flex; clear: both;"
            v-else
          >
            <!-- Message -->
            <div
              style="align-self: center; margin-right: 10px; padding: 5px 10px 5px 10px; background-color: #26BA50; border-radius: 5px; color: #fff;"
            >
              <span class="meeting-font-medium">{{ documentNote.description }}</span>
            </div>
            <!-- Avatar -->
            <div>
              <avatar
                v-if="documentNote.avatar"
                :src="documentNote.avatar"
                backgroundColor="#26BA50"
                color="#fff"
                :size="40"
                style="align-self: start;"
              ></avatar>
              <avatar
                v-else
                :username="documentNote.fullName"
                backgroundColor="#26BA50"
                color="#fff"
                :size="40"
                style="align-self: start;"
              ></avatar>
            </div>
          </div>
        </div>
      </div>
      <div style="clear: both;"></div>
      <div
        style="display: flex; margin-top: 20px; background-color: #fff; width: 100%; bottom: 0; border-bottom: 1px solid rgb(125, 125, 125);"
      >
        <div style="width: 100%;">
          <input
            type="text"
            :placeholder="$t('support.supportContentChat')"
            style="width: 100%; border: none !important; padding: 20px;"
            class="form-control meeting-font-medium"
            v-model="noteDescription"
            v-on:keyup.enter="createNoteInDocument"
          />
        </div>
        <div
          style="float: right; margin-right: 20px; align-self: center; cursor: pointer;"
          @click="createNoteInDocument"
        >
          <img src="../../assets/icons/send.svg" width="20px;" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import { mapActions, mapGetters } from "vuex";
import functionUtils from 'utils/functionUtils'
export default {
  props: {
    documentId: {
      type: Number,
      default: () => {
        return null;
      },
    },
    eventId: {
      type: Number,
      default: () => {
        return null;
      },
    },
  },
  components: {
    avatar: Avatar,
  },
  data: () => ({
    noteDescription: [],
    documentNotes: [],
  }),
  computed: {
    ...mapGetters(["DOCUMENT_NOTES_DATA", "GET_CURRENT_ACCOUNT"]),
  },
  watch: {
    DOCUMENT_NOTES_DATA: function() {
      let data = this.DOCUMENT_NOTES_DATA;
      this.documentNotes = []
      for (let i = 0, size = data.length; i < size; i++) {
        let documentNoteObj = {
          inReviewPhase: data[i].in_review_phase,
          fullName:
            data[i].user_created &&
            data[i].user_created.last_name +
              " " +
              data[i].user_created.first_name,
          avatar:
            data[i].user_created.profile &&
            data[i].user_created.profile.url_avatar,
          userId: data[i].user_created.id,
          description: data[i].description,
        };
        this.documentNotes.push(documentNoteObj);
      }
    },
  },
  created() {
    this.getNoteInDocument()
  },
  methods: {
    /**
     * Get note in document
     */
    getNoteInDocument: function () {
      let filter = {
        params: {
          eventId: this.eventId,
          documentId: this.documentId,
        },
      };
      this.GET_DOCUMENT_NOTES(filter);
    },
    /**
     * Create note in document
     */
    createNoteInDocument: function() {
      if (!functionUtils.isNull(this.noteDescription) && !functionUtils.isEmptyString(this.noteDescription)) {
        let filter = {
          documentId: this.documentId,
          description: this.noteDescription
        }
        this.CREATE_NOTE_IN_DOCUMENT(filter).then(
          function () {
            this.noteDescription = ""
            this.getNoteInDocument()
          }.bind(this)
        )
      }
    },
    ...mapActions(["GET_DOCUMENT_NOTES", 'CREATE_NOTE_IN_DOCUMENT']),
  },
};
</script>
