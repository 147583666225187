<template>
  <div style="position: absolte;" v-clickoutside="removeToggleSearchForItemsInput">
    <div
      style="position: relative;"
    >
      <i
        class="fas fa-search"
        style="position: absolute; left: 10px; top: 30%; color: #cccccc; font-size: small;"
      ></i>
      <input
        v-model="searchForItem"
        @focus="toggleSearchForItemsInput()"
        type="text"
        class="form-control mt-1"
        style="padding-left: 30px; font-size: small;"
        :placeholder="placeholder"
      />
    </div>
    <div
      v-show="showApproverList"
      class="approver-dropdown"
      style="background-color: rgb(248, 248, 248) !important; width: 100%; margin-top: 5px; max-height: 300px; overflow-y: auto; border-radius: 5px;"
    >
      <table class="table">
        <thead v-if="headers.length > 1">
          <tr>
            <th
              nowrap
              v-for="(header, index) in headers"
              :key="header + index"
              style="border: none !important;"
              scope="col"
              class="text-left"
            >
              {{ $t(header.name) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in filteredItems"
            :key="item + index"
            @click="itemSelect(item)"
            style="cursor: pointer;"
          >
            <td style="font-size: 14px;" :class="header.key == 'check' ? 'text-center' : 'text-left'" v-for="(header, index) in headers" :key="header + index" :width="header.key == 'check' ? '10%' : '30%'">
              <b-check v-if="header.key == 'check'" v-model="item.selected" @change="onSelectChange(item)"></b-check>
              <b-form-select v-else-if="header.key == 'role'" v-model="item.role" style="font-size: 14px;">
                <option v-for="option in options" :value="option.value" :key="option.value + option.text">{{ $t(option.text) }}</option>
              </b-form-select>
              <span v-else>
                {{ item[header.key] }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import stringUtils from 'utils/stringUtils'
export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    headers: {
      type: Array,
      default: () => {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: () => {
        return "";
      }
    }
  },
  data: () => ({
    selected: null,
    options: stringUtils.DocumentRole,
    showApproverList: false,
    searchForItem: "",
    approverSelected: ""
  }),
  computed: {
    /**
     * Filtered approver list
     */
    filteredItems() {
      return this.items.filter(item => {
        return item.fullname
          .toLowerCase()
          .includes(this.searchForItem.toLowerCase());
      });
    }
  },
  methods: {
    /**
     * On select change
     */
    onSelectChange: function (item) {
      this.$emit('multipleSelect', item)
    },
    /**
     * Item select
     */
    itemSelect: function(item) {
      this.$emit('select', item)
      // this.searchForItem = item.name;
      // this.$emit("select", item);
    },
    /**
     * Event show approvers when focus inside input
     */
    toggleSearchForItemsInput: function() {
      this.showApproverList = true;
    },
    /**
     * Hide approvers when focus outside input
     */
    removeToggleSearchForItemsInput: function() {
      this.showApproverList = false;
    }
  },
  mounted() {},
  directives: {
    clickoutside: {
      bind: function(el, binding, vnode) {
        el.clickOutsideEvent = function(event) {
          // here I check that click was outside the el and his childrens
          if (!(el == event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
        document.body.addEventListener("touchstart", el.clickOutsideEvent);
      },
      unbind: function(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
        document.body.removeEventListener("touchstart", el.clickOutsideEvent);
      },
      stopProp(event) {
        event.stopPropagation();
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.approver-dropdown {
  position: absolute;
  display: block;
  z-index: 10;
}
</style>
