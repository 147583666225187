const DocumentStatus = {
    AWAITING_APPROVAL: 1,
    COMPLETE: 2,
    APPROVED: 3,
    REJECTED: 4,
    PERSONAL: 5,
    SUMMARY: 6,
    PERSONAL_UPLOAD: 7
}
export default DocumentStatus
